<template>
  <v-container style="margin-top:160px;"  fluid>
     <!-- <v-img src="img/flash.png" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;"></v-img> -->
      <v-card class="rounded-lg" elevation="10" v-if="this.task1.length>0">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px">Обов’язкові</v-card-title>
          <v-card-text>
            
            
            <v-row v-for="(card, i) in task1" :key="i"> 
               
               <v-col><task_btn 
               :failed="card['moderate']!='1'&&card['correct']=='0'"
               :moderation="card['moderate']=='1'"
               :success="card['moderate']!='1'&&card['correct']=='1'"
                  @click.native="
                     $router.push({
                     name: links[card['data']['type']],
                     params: { routeparam: card.id },
                     })
                  "
               
               
               :t_num="i+1"
               color_t="#f5989d" style="box-shadow:unset !important"                 
                >{{ card["data"]["header"] }}<br>({{card['start_date']}}-{{card['end_date']}})
                </task_btn>
                </v-col>
             </v-row>
            
          </v-card-text>
      </v-card>


      <v-card class="rounded-lg" style="margin-top:35px;" elevation="10" v-if="this.task2.length>0">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px">Додаткові</v-card-title>
          <v-card-text>
             <v-row  v-for="(card2, i) in task2" :key="i">
                 <v-col><task_btn 
                 :failed="card2['moderate']!='1'&&card2['correct']=='0'"
               :success="card2['moderate']!='1'&&card2['correct']=='1'"
                  @click.native="
                     $router.push({
                     name: links[card2['data']['type']],
                     params: { routeparam: card2.id },
                     })
                  "
               
               
               :t_num="i+1"
                 color_t="#c4df9b" style="box-shadow:unset !important"                 
                >{{ card2["data"]["header"] }}<br>({{card2['start_date']}}-{{card2['end_date']}})
                </task_btn>
                </v-col>
            
             </v-row>
          </v-card-text>
          <v-overlay :absolute="true" v-if="!nec_executed">
            Спочатку виконайте обов’язкові завдання
            <br />
         </v-overlay>
      </v-card>

      <v-card class="rounded-lg" style="margin-top:35px;" elevation="10" v-if="this.task3.length>0">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px">Екстразавдання</v-card-title>
          <v-card-text>
             <v-row  v-for="(card3, i) in task3" :key="i">
                 <v-col><task_btn 
                 :failed="card3['moderate']!='1'&&card3['correct']=='0'"
               :moderation="card3['moderate']=='1'"
               :success="card3['moderate']!='1'&&card3['correct']=='1'"
                  @click.native="
                     $router.push({
                     name: links[card3['data']['type']],
                     params: { routeparam: card3.id },
                     })
                  "
               
               
               :t_num="i+1"
                 color_t="#c4df9b" style="box-shadow:unset !important"                 
                >{{ card3["data"]["header"] }}<br>({{card3['start_date']}}-{{card3['end_date']}})
                 </task_btn> <!--:t_num="card3['data']['num_in_month']" -->
                </v-col>
            
             </v-row>
             
            
          </v-card-text>

          <!-- <v-overlay :absolute="true" v-if="!nec_executed">
            Спочатку виконайте обов’язкові завдання
            <br />
         </v-overlay> -->
      </v-card>













      
<v-dialog v-model="new_type_task_info">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          Увага!  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
           <span >Дорогі партнери!<br>
На період військового стану вам буде надаватися можливість вибору рівня складності обов'язкових завдань. Кількість балів, що нараховується, залежить від обраного рівня. Обирати новий рівень складності можна кожні 2 тижні, а тип контракту при цьому залишається незмінним.
</span>
            </v-col>
          </v-row>
        
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="new_type_task_info = false;$router.push({name:'Tasks'})">  До завдань </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- new_task_requirements_block_end -->
  </v-container>
</template>
          
<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
import task_btn from "../components/task_btn.vue";
//import l_switch from "../components/lang_switch.vue";
export default {
  watch:{
    

    

  },
  components:{task_btn},
  computed: {
     nec_executed: function(){
         var not_exec_task_nec = this.task1.filter((i) => i.ex == null&&i.id_ex_previous == null);
         console.log(not_exec_task_nec);
         return not_exec_task_nec.length==0||this.cards.filter((i) => i.type >10).length!=0;
     },
    task1: function () {
      return this.cards.filter((i) => i.type == "1"||i.type == "11"||i.type == "21");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2"||i.type == "12"||i.type == "22");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3"||i.type == "4"||i.type == "10");
    },
  },
  data: () => ({
    
    overlay_main: true,
    cards: [],
    links: {
      sku_photo: "Phototask",
      lay_photo: "Phototask",
      mlevel_sku_photo: "Phototask",
      mlevel_sku_photo_dmx: "Phototask_dmx",
      mlevel_sku_photo_dmx_multi: "Phototask_dmx",
      mlevel_lay_photo: "Phototask",
      
      ex_photo: "Phototask",
      ex_photo_code: "Phototask",
      ex_photo_timer: "Phototask",
      ex_photo_dmx: "Phototask_dmx",
      ex_photo_dmx_multi: "Phototask_dmx",
      custom_extra_photo: "Phototask",
      test: "Testtask",

      ka_main:"Ka_main",

    },
    new_type_task_info:false,
  }), 
  methods: {
  task_go:function(){
    
            axios
      .post(
        API_LOCATION,
        {
          
          action: "task_go"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.state=='tt_check_messsage'){
           this.$router.push("/Main"); 
        }
        /*
        else if(resp.data.state=='temp_task')
        {
          this.$router.push("/asssel_temp");
        }
        else if(resp.data.state=='new_type_task_info')
        {
          this.new_type_task_info=true;
        }*/
        

      });


    
  },  
    
  },
    mounted() {
      
    console.log(this.links);
    console.log(this.$route.params.routeparam);
    axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "get_tasks",
          filter: this.$route.params.routeparam,
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars
        resp.data.forEach((element) => {
          this.cards.push({
            id: element.id,
            type: element.type,
            start_date: element.start_date,
            end_date: element.end_date,
            data: JSON.parse(element.data_json),
            ex: element.id_ex,
            id_ex_previous: element.id_ex_previous,
            correct: element.correct,
            moderate: element.moderate
          });
          console.log(element.moderate!='1'&&element.correct=='1');
        });
        this.overlay_main = false;
        console.log(resp.data);
        //console.log(this.cards);
      });
  },
  created() {
    //this.task_go();

    this.$store.dispatch("profileset");
     this.$store.dispatch("page_title_set", "Завдання");
    axios
      .post(
        API_LOCATION,
        {
          
          action: "api_check"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              console.log(resp.data);         
        
      });











  },
};
</script>